// paperMaps.js
export const Vol1_Issue1_paperMap = {
    'Complete Volume 1 Issue 1 : Dec 2023': '/papers/volume-1 issue-1/Volume1Issue1Dec2023_8-7-2024.pdf',
    'PIJET-01: Pneumatic end-effector for precise seeding': '/papers/volume-1 issue-1/Final revised paper_Pijet-1.pdf',
    'PIJET-02: Optimizing Single Image Super-resolution and upscaling for resource-constrained computing environments': '/papers/volume-1 issue-1/Final revised paper_Pijet-2.pdf',
    'PIJET-03: Musical Frequency Note Detection': '/papers/volume-1 issue-1/Final revised paper_Pijet-3.pdf',
    'PIJET-04: Revolutionizing Skin Disease Classification with Machine Learning': '/papers/volume-1 issue-1/Final revised paper_Pijet-4.pdf',
    'PIJET-05: Comparative Analysis of Malaria Detection Using Predictive Algorithms': '/papers/volume-1 issue-1/Final revised paper_Pijet-5.pdf',
    'PIJET-06: Semantic Web and Ontologies': '/papers/volume-1 issue-1/Final revised paper_Pijet-6.pdf',
    'PIJET-07: Smart Chatbot with Document Retrieval and Extractive Question Answering': '/papers/volume-1 issue-1/Final revised paper_Pijet-7.pdf',
    'PIJET-08: Multimodal Machine Learning': '/papers/volume-1 issue-1/Final revised paper_Pijet-8.pdf',
    'PIJET-09: An In-depth Exploration of Human Pose Estimation': '/papers/volume-1 issue-1/Final revised paper_Pijet-9.pdf',
    'PIJET-10: Bilingual Minutes of the Meet Generator': '/papers/volume-1 issue-1/Final revised paper_Pijet-10.pdf',
    'PIJET-11: Towards Addressing Bias and Fairness in Machine Learning': '/papers/volume-1 issue-1/Final revised paper_Pijet-11.pdf',
    'PIJET-12: Affordable Vehicle Tracking System': '/papers/volume-1 issue-1/Final revised paper_Pijet-12.pdf',
    'PIJET-13: Analysis And Modelling of Universal Buffer Circuit for Guitar Pedals': '/papers/volume-1 issue-1/Final revised paper_Pijet-13.pdf',
    'PIJET-14: Unlocking the Potential of Smart Devices: The Synergy Between Blockchain and IoT using RBM': '/papers/volume-1 issue-1/Final revised paper_Pijet-14.pdf',
    // Add more papers as needed
};

export const Vol1_Issue2_paperMap = { 
    'Complete Volume 1 Issue 2 : June 2024': '/papers/volume-1 issue-2/Volume1-Issue2.pdf',
    'PIJET-01: Enhancing battery life by performance analysis using ML and DL': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-01.pdf',
    'PIJET-02: Early Detection of Abnormal Lung Condition based on Deep Learning Model': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-02.pdf',
    'PIJET-03: Coder’s Battle Room Using Cloud Computing': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-03.pdf',
    'PIJET-04: Algo-Powered Banking: Enhancing Investment Decisions Through Machine Learning': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-04.pdf',
    'PIJET-05: Imparting Education using Augmented Reality': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-05.pdf',
    'PIJET-06: DDoS Attack Detection and Mitigation Technique': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-06.pdf',
    'PIJET-07: Dynamic Map Stitching in Swarm Robotics': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-07.pdf',
    'PIJET-08: Enhancing User-Helper Interactions-A Survey of Middleware Website Powered by AI': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-08.pdf',
    'PIJET-09: Arrival Time Prediction for Transportation System': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-09.pdf',
    'PIJET-10: Investigative Study of Data Tiering Systems and Supports': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-10.pdf',
    'PIJET-11: Identification of Melanoma Skin Cancer Through Image Processing and DL': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-11.pdf',
    'PIJET-12: Secure Encryption of Documents and Smart Complaint Management for Banking Applications': '/papers/volume-1 issue-2/Final Revised Paper_Pijet-12.pdf',
    'PIJET-13: Design and Implementation of 8 - Bit ALU using Verilog': '/papers/volume-1 issue-2/Final_Revised Paper_Pijet-13.pdf',
    'PIJET-14: Optimization Techniques in Machine Learning and Deep Learning': '/papers/volume-1 issue-2/Final_Revised Paper_Pijet-14.pdf',
    // Add more papers as needed
}